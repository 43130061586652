// src/App.js
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

const App = () => {
  // Variables for the API URL
  const sha512password = '3af06cb90efb810287f4279fef8063159959d66b62e9118a9221b44bb5294e3961838518697ea646e8585698096384cdaeb18c1aef3b1742fbc6a9cd4b93351c';
  const user = 'apiread';
  const company = '1';
  const version = '3.9';

  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Function to handle login
  const handleLogin = async () => {
    try {
      await instance.loginPopup(loginRequest);
    } catch (error) {
      console.error("Login failed: ", error);
    }
  };

  // Fetch data only if the user is authenticated
  useEffect(() => {
    if (isAuthenticated) {
      const fetchData = async () => {
        try {
          const response = await fetch(
            `https://camarguegroup.synergist.cloud/jsonapi/jobs.json?modelstructure=jobslist&sha512password=${sha512password}&user=${user}&company=${company}&version=${version}`
          );
          const result = await response.json();
          setData(result.data || []);
          setFilteredData(result.data || []);
          setLoading(false);
        } catch (err) {
          setError("Failed to fetch data");
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [isAuthenticated]);

  // Handle search input
  useEffect(() => {
    const lowercasedSearch = search.toLowerCase();
    const filtered = data.filter(
      (item) =>
        item.jobNumberSimple.toLowerCase().includes(lowercasedSearch) ||
        item.jobClientCode.toLowerCase().includes(lowercasedSearch) ||
        item.jobDescription1stLine.toLowerCase().includes(lowercasedSearch) ||
        item.jobClientName.toLowerCase().includes(lowercasedSearch) ||
        item.jobHandlerFullName.toLowerCase().includes(lowercasedSearch) ||
        item.jobCustomField6.toLowerCase().includes(lowercasedSearch)
    );
    setFilteredData(filtered);
  }, [search, data]);

  // Define columns for the DataTable
  const columns = [
    // Your existing column definitions
    {
      name: 'SharePoint Link',
      selector: row => row.jobCustomField6,
      sortable: true,
      width: '60px',
      cell: row => (
        row.jobCustomField6 ? (
          <a href={row.jobCustomField6} target="_blank" rel="noopener noreferrer">
            <img
              src="shpt.png"
              alt="SharePoint Link"
              style={{ width: '20px', height: '20px', cursor: 'pointer' }}
            />
          </a>
        ) : null
      )
    },
    {
      name: 'Job Number',
      selector: row => row.jobNumberSimple,
      sortable: true,
      width: '100px', // Adjusted width
      cell: row => (
        row.jobNumberSimple ? (
          <a href="https://camarguegroup.synergist.cloud/" target="_blank" rel="noopener noreferrer">
            {row.jobNumberSimple}
          </a>
        ) : null
      )
    },
    {
      name: 'Client Code',
      selector: row => row.jobClientCode,
      sortable: true,
      width: '120px' // Adjusted width
    },
    {
      name: 'Description',
      selector: row => row.jobDescription1stLine,
      sortable: true
    },
    {
      name: 'Client Name',
      selector: row => row.jobClientName,
      sortable: true
    },
    {
      name: 'Job Handler',
      selector: row => row.jobHandlerFullName,
      sortable: true
    }
  ];

  if (!isAuthenticated) {
    return (
      <div className="login-container">
        <img src="logo.png" alt="Logo" className="logo-login" />
        <h1>Please sign in to access the application</h1>
        <button onClick={handleLogin} className="btn btn-primary">
          Sign In
        </button>
      </div>
    );
  }

  return (
    <div className={`app-container ${isMenuOpen ? "menu-open" : ""}`}>
      <header className="app-header">
        <button
          className="hamburger-icon"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {isMenuOpen ? "×" : "☰"}
        </button>
        <img src="logo.png" alt="Logo" className="logo" />
        {!isAuthenticated && (
          <button className="btn btn-primary" onClick={handleLogin}>
            Sign In
          </button>
        )}
        {isAuthenticated && (
          <div>
            <span>Welcome, {accounts[0].name}</span>
          </div>
        )}
      </header>

      {/* Side Menu */}
      <div className={`side-menu ${isMenuOpen ? 'open' : ''}`}>
        <button className="close-btn" onClick={() => setIsMenuOpen(false)}>×</button> {/* Close button */}
        <h2 className="NavHeader">Useful Links</h2>
        <ul>
          <li><a href="https://camargue.sharepoint.com/_layouts/15/sharepoint.aspx" target="_blank" rel="noopener noreferrer">Client Data</a></li>
          <li><a href="https://camargue.sharepoint.com/sites/CRH" target="_blank" rel="noopener noreferrer">Central Resources</a></li>
          <li><a href="https://camargue.sharepoint.com/sites/HR_Hub" target="_blank" rel="noopener noreferrer">HR Hub</a></li>
          <li><a href="https://apps.powerapps.com/play/e/default-46d4e256-b3be-4371-9b3c-480cbc8d7489/a/f95044d6-4723-4790-867e-3d05365ddfb3?tenantId=46d4e256-b3be-4371-9b3c-480cbc8d7489&source=sharebutton&sourcetime=1723220431195#" target="_blank" rel="noopener noreferrer">Rooms</a></li>
          <li><a href="https://app.pleo.io/login" target="_blank" rel="noopener noreferrer">Pleo</a></li>
          <li><a href="https://camarguegroup.synergist.cloud" target="_blank" rel="noopener noreferrer">Synergist</a></li>
          <li><a href="https://portal.office.com" target="_blank" rel="noopener noreferrer">Microsoft Portal</a></li>
          <li><a href="https://camargue.sharepoint.com/sites/HR_Hub/SitePages/Cyber-Security.aspx" target="_blank" rel="noopener noreferrer">IT Support</a></li>
          <li><a href="https://portal.compleat.online/" target="_blank" rel="noopener noreferrer">iCompleat</a></li>
          <li><a href="https://login.xero.com/identity/user/login?ReturnUrl=%2Fidentity%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Dxero_business_xeroweb_hybrid-web%26redirect_uri%3Dhttps%253A%252F%252Fgo.xero.com%252Fsignin-oidc%26response_mode%3Dform_post%26response_type%3Dcode%2520id_token%26scope%3Dxero_all-apis%2520openid%2520email%2520profile%26state%3DOpenIdConnect.AuthenticationProperties%253Dn9uVSAdZxOmsFZdo4SYy90Y0NXvoCS8jzawGZ8NAzmjK2I1R9dwe__zZ88JfbbLTiuLFKx8IG7FEK3aJLyCL9vE1A_EzkWUYeDPVfVe8j5ETd0IdnkDE-WYL3EfuEc1nImKAzFK45CXizOxAFGwLaRQaIHwlwgy0yN7G8PvRv3MedmbLnX_ZpVeyW8hifTLfx_ekt0QGXGYHi2M_vB0SG4U_QNA%26nonce%3D638461142653020714.ZTdjMzEwYWQtZDZiNS00NGRhLWJlNjUtZmQ0YThlOGNhOWU2NGRlOTIyZmMtNzQ2OC00NWI5LWJmMGYtODhjNzIxZTc2ZTk3%26x-client-SKU%3DID_NET451%26x-client-ver%3D1.3.14.0" target="_blank" rel="noopener noreferrer">Xero</a></li>
          <li><a href="https://cbonline.lloydsbank.com/PrimaryAuth/" target="_blank" rel="noopener noreferrer">Lloyds</a></li>
          <li><a href="https://app.fathomhq.com/login?ReturnUrl=%2fmy-companies" target="_blank" rel="noopener noreferrer">Fathom</a></li>
          <li><a href="https://jamesforddesignteam.app.streamtime.net/#dashboard" target="_blank" rel="noopener noreferrer">StreamTime</a></li>
        </ul>
      </div>


      <div className="container mt-3">
        <h1 className="mb-4">Searchable Job Table</h1>
        <ul>
          <li>Please click the SharePoint logo in the left column to access the cloud client data for that job.</li>
          <li>Click the job number to access Synergist, then click 'Jobs' and search for the job number.</li>
          <li>This also shows a description, client name and the job handler for the job.</li>
          <li>The search functionality below can search on any column data.</li>
        </ul>
        <input
          type="text"
          className="form-control mb-3"
          placeholder="Search..."
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p className="text-danger">{error}</p>
        ) : (
          <DataTable
            title="Jobs List"
            columns={columns}
            data={filteredData}
            pagination
            paginationPerPage={50} // Set default rows per page to 50
            highlightOnHover
            pointerOnHover
          />
        )}
      </div>
    </div>
  );
};

export default App;